import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Seo from '../components/seo'
import he from 'he'

import Slider from 'react-slick'

class ProductTemplate extends Component {

  state = {
    variant: this.props.data.shopifyProduct.variants[0],
    price: this.props.data.shopifyProduct.variants[0].price,
    viewCart: false,
  }

  _selectVariant = (variant) => () => {
    this.setState({ 
      variant, 
      price: variant.price
    })
  }

  _addToCart = () => {
    let { variant } = this.state
    this.setState({viewCart: true})

    if (variant.shopifyId.length)
      this.props.addVariantToCart(variant.shopifyId, 1)
  }

  render () {
    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
    }

    let { shopifyProduct } = this.props.data
    let { variant, price, viewCart } = this.state

    return (
      <>
        <Seo title={he.decode(shopifyProduct.title)} />
        <div className='single-product'>
          <div className='single-product__inner container'>
            <div className='single-product__left'>
              <Slider {...settings}>
                { shopifyProduct.images.map((el, i) => <img key={i} src={el.originalSrc} alt={shopifyProduct.title} />) }
              </Slider>
            </div>
            <div className='single-product__right'>
              <div className='single-product__info'>
                <h4>{ shopifyProduct.productType }</h4>
                <h2>{shopifyProduct.title} — ${price}</h2>
                <h4 className='border-top'>Why should you { shopifyProduct.title }</h4>
                <p dangerouslySetInnerHTML={{__html: shopifyProduct.descriptionHtml }} />
              </div>
              <div className='single-product__add'>
                <div className='single-product__heading'>
                  <h4 className='border-top'>Quantity</h4>
                </div>
                <div className='single-product__quantity'>
                  { shopifyProduct.variants.map((el, i) => {
                    let props = {
                      key: i,
                      className: `btn--quantity ${el.shopifyId === variant.shopifyId ? 'active' : ''}`,
                      onClick: this._selectVariant(el),
                    }
                    return (<button {...props}>{ el.title }</button>)
                  }) }
                </div>
                <div className='single-product__cart'>
                  <button className='btn--cart' onClick={this._addToCart}>Add to cart</button>
                  { viewCart && 
                    <Link to="/cart" className='btn--cart btn--view'>View Cart</Link>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ProductTemplate

export const pageQuery = graphql`
  query currentProduct($id: String!) {
    shopifyProduct(id: { eq: $id }) {
      id
      title
      handle
      productType
      descriptionHtml
      vendor
      images {
        originalSrc
      }
      options {
        name
      }
      variants {
        id
        shopifyId
        title
        price
        availableForSale
      }
    }
  }
`
